import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import '../components/Introduction.css';
import '../components/WhatIDo.css';
import './404page.css';

import "aos/dist/aos.css";
import AOS from "aos";

export function Error404(){
  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // Hover Effect
  const input404Ref = useRef(null);
  const [positions, setPositions] = useState([0,0]);
  function hover404Effect(event){
    let y = event.clientY;
    let x = event.clientX;
    let array = input404Ref.current.getBoundingClientRect();
    let left = (array.left+array.right)/2
    let top = (array.top+array.bottom)/2

    let input_x
    if((x-left) >= 0){
      input_x = 1*Math.log(x-left)
    }else{
      input_x = -1*Math.log(Math.abs(x-left))
    }

    let input_y
    if((y-top) >= 0){
      input_y = 1*Math.log(y-top)
    }else{
      input_y = -1*Math.log(Math.abs(y-top))
    }

    setPositions([input_x,input_y])
  };

  return(
    <div className='introduction-container'>
      <div className='introduction-body' onMouseMove={hover404Effect}>
        <div className='text-container'>
          <div className='JW-title-wrap' data-aos='fade-down'>UH OH! You're lost.</div>
          <br/>
          <div className = 'not-found-page-container'>
            <div className='not-found-page' ref={input404Ref} style={{marginTop: positions[1]+'px', marginLeft: positions[0]+'px'}}>
              404
            </div>
          </div>
          <br/><br/>
          <Link
            className='LinkButton'
            id='centerLink'
            to='/' 
            aria-label='Home page'
            data-aos='zoom-in-up'
          >
            <span className='LinkButton_text JW-patterns'>Return to home</span>
            <span className='drop-background' style={{transform: 'translate('+positions[0]+'px,'+positions[1]+'px)'}}>Return to home</span>
          </Link>
        </div>
      </div>
    </div>
  )
}