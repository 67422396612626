import React from 'react';
import { Introduction } from '../components/Introduction.js';
import { WhatIDo } from '../components/WhatIDo.js';
import { MyPortfolio } from '../components/MyPortfolio.js';

export function HomePage(){
  return(
    <>
      <Introduction/>
      <WhatIDo/>
      <MyPortfolio/>
    </>
  )
}