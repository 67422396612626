import React from 'react';
import FontAwesome from 'react-fontawesome';

import 'font-awesome/css/font-awesome.min.css';
import 'font-awesome/css/font-awesome.css';
import './Footer.css';

export function Footer(){
  return(
    <footer>
      <ul>
        <li>
          <a href='https://www.linkedin.com/in/jonah-winninghoff-ms-2b9b167b/' 
            aria-label="Jonah Winninghoff's LinkedIn profile"
            target = '_blank' rel="noopener noreferrer">
            <FontAwesome name='linkedin' className='navbar-fontawesome'/>
          </a>
        </li>
        <li>
          <a href='https://github.com/jonahwinninghoff' 
            aria-label="Jonah Winninghoff's GitHub profile"
            target = '_blank' rel="noopener noreferrer">
            <FontAwesome name='github' className='navbar-fontawesome'/>
          </a>
        </li>
        <li>
          <a href='mailto:jonah.winninghoff@gmail.com'
            aria-label="Jonah's email address">
            <FontAwesome name='envelope' className='navbar-fontawesome'/>
          </a>
        </li>
      </ul>
    </footer>
  )
}