import React, { useEffect, useState } from 'react';
import picture_of_me from '../assets/pictureOfme.png'
import { Link } from 'react-router-dom';

import './portfolio.css';
import "aos/dist/aos.css";
import AOS from "aos";

export function AboutMe(){
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // Hover Effect
  const [zoomPositions, setzoomPositions] = useState([0,0]);
  const [rotateGear, setRotateGear] = useState(0);

  const followingMouse = (event) => {
    let mouseX = event.clientX;
    let mouseY = event.clientY;

    const dist = 0.2;
    let input_x;
    let input_y;

    input_x = dist*Math.sin(mouseX/window.innerWidth)
    input_y = dist*Math.sin(mouseY/window.innerHeight)

    setzoomPositions([input_x,input_y]);
    setRotateGear(mouseY);
  }
  
  return(
    <div className='introduction-container'>
      <div className='work-body' onMouseMove={followingMouse}>
        <div className='text-introduction-work-container'>
          <h1 className='JW-work-title' data-aos='fade-down' role = 'main'>About Me<span className='JW-work-dot'>.</span></h1>
          <div className='JW-work-dash'/>
          <div className='JW-work-text' data-aos='fade-up'>
            I am <b>Deaf</b> and an <b>ASL user</b> who has been involved with data science and website development worlds since 2019. 
            In my profession, I have worked with National Deaf Center, Rochester Institute of Technology, and Gallaudet 
            University&mdash;wrestling with AI/ML development, data, 
            and website development challenges.
          </div>    
          <div id = 'text-above-vertical-line' data-aos='fade-left'>Scroll<div id='vertical_line'/></div>  
        </div>
        <div className = 'flex-direction-row column-reverse'>
          <svg viewBox='-2 -5 13 11.5' className = 'work-size-svg' data-aos='fade-down' alt="A purple icon featuring Seattle's Space Needle, set against a hill and towering above the clouds.">
            <defs>
              <pattern id="svg-pattern" x="0" y="0" width="21" height="21" patternUnits="userSpaceOnUse" patternTransform="translate(11, 11) rotate(15) skewX(0) scale(0.01)">
                <svg width="10" height="10" viewBox="0 0 20 20">
                  <g fill="var(--transDarkerBlue)" opacity="1">
                    <circle cx="10" cy="10" r="10">
                    </circle>
                  </g>
                </svg>
              </pattern>
            </defs>
            <path fill='url(#svg-pattern)' transform={'translate('+(zoomPositions[0]+1)+' '+zoomPositions[1]+')'} d='M -2 2 A 0.1 0.1 0 0 0 9.5 1 A 0.1 0.1 0 0 0 -2 2 Z'/>
            <path fill='var(--whitishPurple)' transform='translate(0 1)' strokeWidth='0.7px' stroke='var(--whitishPurple)' d='M -4 6 Q 0 3 3 -1 Q 5 -3 5.5 -2 Q 6 -1 6.5 -1.5 Q 7.5 -2 8 -1 Q 10 4 12 6 Q 9 6 -4 6 Z' />
            <path fill='var(--transBlue)' transform={'translate('+zoomPositions[0]+' '+(zoomPositions[1]+1)+')'} strokeWidth = '0' d='M -4 6 Q 0 3 3 -1 Q 5 -3 5.5 -2 Q 6 -1 6.5 -1.5 Q 7.5 -2 8 -1 Q 10 4 12 6 Q 9 6 -4 6 Z ' />
            <path fill='transparent' transform='translate(0 1)'strokeWidth = '0.1' stroke='var(--purplishBlue)' d='M -4 6 Q 0 3 3 -1 Q 5 -3 5.5 -2 Q 6 -1 6.5 -1.5 Q 7.5 -2 8 -1 Q 10 4 12 6 Q 9 6 -4 6 Z' />
            <path fill='var(--whitishPurple)' transform='translate(0 -1) scale(1.2 1.2)' strokeWidth = '0' d='M 3 5 A 0.1 0.1 0 0 1 4 4 Q 4 2.8 5 3 A 0.1 0.1 0 0 1 7 3 Q 8 3 8 4 Q 9 4 9 5' />
            <path fill='transparent' transform='translate(0 -1) scale(1.2 1.2)' strokeWidth = '0.1' stroke='var(--purplishBlue)' d='M 3 5 A 0.1 0.1 0 0 1 4 4 Q 4 2.8 5 3 A 0.1 0.1 0 0 1 7 3 Q 8 3 8 4 Q 9 4 9 5' />
            <path fill='var(--whitishPurple)' transform={'translate('+zoomPositions[0]+' '+zoomPositions[1]+')'} strokeWidth='0.5' stroke='var(--whitishPurple)' d='M 3.9 -3.9 A 0.1 0.1 0 0 0 4.1 -3.9 A 0.1 0.1 0 0 0 3.9 -3.9 Z M 4 -3.9 L 4 -3.2 L 4 -3.9 Z M 3.6 -2.8 Q 3.8 -3 3.6 -3.1 Q 3.5 -3.15 3.65 -3.2 Q 4 -3.3 4.35 -3.2 Q 4.5 -3.15 4.4 -3.1 Q 4.2 -3 4.4 -2.8 Q 4 -2.85 3.6 -2.8 Z M 3 -2.65 Q 4 -3 5 -2.65 L 5 -2.6 Q 4 -2.8 3 -2.6 Z M 3 -2.6 L 3 -2.44 Q 4 -2.65 5 -2.44 L 5 -2.6 Q 4 -2.8 3 -2.6 Z M 2.7 -2.35 Q 4 -2.7 5.3 -2.35 Q 6 -2.1 5 -2 L 5 -2.1 Q 4 -2.4 3 -2.1 L 3 -2 Q 2 -2.2 2.7 -2.35 Z M 3 -2.1 L 3 -1.95 Q 4 -2.2 5 -1.95 L 5 -2.1 Q 4 -2.4 3 -2.1 Z M 3 -1.95 Q 4 -2.2 5 -1.95 Q 5.3 -1.85 5.1 -1.8 Q 4 -1.4 2.9 -1.8 Q 2.7 -1.85 3 -1.95 ' />
            <path fill='transparent' strokeWidth='0.08' stroke='var(--purplishBlue)' d='M 4 -3.9 L 4 -3.2 L 4 -3.9 Z' />
            <path fill='transparent' strokeWidth='0.08' stroke='var(--purplishBlue)' d='M 3.9 -3.9 A 0.1 0.1 0 0 0 4.1 -3.9 A 0.1 0.1 0 0 0 3.9 -3.9 Z' />
            <path fill='var(--whitishPurple)' strokeWidth='0' d='M 3.6 -2.8 Q 3.8 -3 3.6 -3.1 Q 3.5 -3.15 3.65 -3.2 Q 4 -3.3 4.35 -3.2 Q 4.5 -3.15 4.4 -3.1 Q 4.2 -3 4.4 -2.8 Q 4 -2.85 3.6 -2.8 Z M 3 -2.65 Q 4 -3 5 -2.65 L 5 -2.6 Q 4 -2.8 3 -2.6 Z M 3 -2.6 L 3 -2.44 Q 4 -2.65 5 -2.44 L 5 -2.6 Q 4 -2.8 3 -2.6 Z M 2.7 -2.35 Q 4 -2.7 5.3 -2.35 Q 6 -2.1 5 -2 L 5 -2.1 Q 4 -2.4 3 -2.1 L 3 -2 Q 2 -2.2 2.7 -2.35 Z M 3 -2.1 L 3 -1.95 Q 4 -2.2 5 -1.95 L 5 -2.1 Q 4 -2.4 3 -2.1 Z M 3 -1.95 Q 4 -2.2 5 -1.95 Q 5.3 -1.85 5.1 -1.8 Q 4 -1.4 2.9 -1.8 Q 2.7 -1.85 3 -1.95 ' />
            <path fill='var(--transDarkerBlue)' transform={'translate('+zoomPositions[0]+' '+zoomPositions[1]+')'} strokeWidth='0' d='M 3 -2.6 L 3 -2.44 Q 4 -2.65 5 -2.44 L 5 -2.6 Q 4 -2.8 3 -2.6 Z M 3 -2.1 L 3 -1.95 Q 4 -2.2 5 -1.95 L 5 -2.1 Q 4 -2.4 3 -2.1 Z' />
            <path fill='transparent' strokeWidth='0.08' stroke='var(--purplishBlue)' d='M 3.6 -2.8 Q 3.8 -3 3.6 -3.1 Q 3.5 -3.15 3.65 -3.2 Q 4 -3.3 4.35 -3.2 Q 4.5 -3.15 4.4 -3.1 Q 4.2 -3 4.4 -2.8 Q 4 -2.85 3.6 -2.8 Z M 3 -2.65 Q 4 -3 5 -2.65 L 5 -2.6 Q 4 -2.8 3 -2.6 Z M 3 -2.6 L 3 -2.44 Q 4 -2.65 5 -2.44 L 5 -2.6 Q 4 -2.8 3 -2.6 Z M 2.7 -2.35 Q 4 -2.7 5.3 -2.35 Q 6 -2.1 5 -2 L 5 -2.1 Q 4 -2.4 3 -2.1 L 3 -2 Q 2 -2.2 2.7 -2.35 Z M 3 -2.1 L 3 -1.95 Q 4 -2.2 5 -1.95 L 5 -2.1 Q 4 -2.4 3 -2.1 Z M 3 -1.95 Q 4 -2.2 5 -1.95 Q 5.3 -1.85 5.1 -1.8 Q 4 -1.4 2.9 -1.8 Q 2.7 -1.85 3 -1.95 ' />
            <path fill='var(--purplishBlue)' strokeWidth='0' d='M 3.75 -1.7 Q 4 -1.8 4.25 -1.7 L 4.25 7 L 3.75 7 Z' />
            <path fill='transparent' strokeWidth='0.02' stroke='var(--whitishPurple)' d='M 3.8 -1.6 L 4.2 -1.4 L 3.8 -1.2 L 4.2 -1 L 3.8 -0.8 L 4.2 -0.6 L 3.8 -0.4 L 4.2 -0.2 L 3.8 0 L 4.2 0.2 L 3.8 0.4 L 4.2 0.6 L 3.8 0.8 L 4.2 1 L 3.8 1.2 L 4.2 1.4 L 3.8 1.6 L 4.2 1.8 L 3.8 2 L 4.2 2.2 L 3.8 2.4 L 4.2 2.6 L 3.8 2.8 L 4.2 3 L 3.8 3.2 L 4.2 3.4 L 3.8 3.6 L 4.2 3.8 L 3.8 4 L 4.2 4.2 L 3.8 4.4 L 4.2 4.6 L 3.8 4.8 L 4.2 5 L 3.8 5.2 L 4.2 5.4 L 3.8 5.6 L 4.2 5.8 L 3.8 6 L 4.2 6.2 L 3.8 6.4 L 4.2 6.6 L 3.8 6.8' />
            <path fill='var(--whitishPurple)' strokeWidth='0' d='M 4 -1.8 Q 4 2 4.25 7 L 4.35 7 L 4.3 6 L 4.53 6 L 4.6 7 L 4.7 7 Q 4.2 0.8 4.25 0.3 Q 4.3 -1 4.6 -1.8 L 4.5 -1.8 L 4.28 -1 Q 4.1 0 4.1 -1 L 4.1 -1.8 Z M 4.3 5.9 L 4.25 5 L 4.46 5 L 4.53 5.9 Z M 4.453 4.9 L 4.24 4.9 L 4.2 4 L 4.39 4 Z M 4.2 3.9 L 4.16 3 Q 4.2 2 4.32 3 L 4.38 3.9 Z M 4 -1.8 Q 4 2 4.25 7 L 4.35 7 L 4.3 6 L 4.53 6 L 4.6 7 L 4.7 7 Q 4.2 0.8 4.25 0.3 Q 4.3 -1 4.6 -1.8 L 4.5 -1.8 L 4.28 -1 Q 4.1 0 4.1 -1 L 4.1 -1.8 Z M 4.3 5.9 L 4.25 5 L 4.46 5 L 4.53 5.9 Z M 4.453 4.9 L 4.24 4.9 L 4.2 4 L 4.39 4 Z M 4.2 3.9 L 4.16 3 Q 4.2 2 4.32 3 L 4.38 3.9 Z M 3.4 -1.8 L 3.5 -1.8 Q 3.74 -1 3.75 0.4 Q 3.75 2 3 7 L 2.9 7 Q 3.65 2 3.65 0.4 Q 3.64 -1 3.4 -1.8 Z' />
            <path fill='transparent' strokeWidth='0.05' stroke='var(--purplishBlue)' d='M 4 -1.8 Q 4 2 4.25 7 L 4.35 7 L 4.35 6 L 4.5 6 L 4.6 7 L 4.7 7 Q 4.2 0.8 4.25 0.3 Q 4.3 -1 4.6 -1.8 M 4.5 -1.8 L 4.28 -1 Q 4.1 0 4.1 -1 L 4.1 -1.8 M 4.3 5.9 L 4.25 5 L 4.46 5 L 4.53 5.9 Z M 4.453 4.9 L 4.24 4.9 L 4.2 4 L 4.39 4 Z M 4.2 3.9 L 4.16 3 Q 4.2 2 4.32 3 L 4.38 3.9 Z M 3.5 -1.8 Q 3.74 -1 3.75 0.4 Q 3.75 2 3 7 L 2.9 7 Q 3.65 2 3.65 0.4 Q 3.64 -1 3.4 -1.8' />
            <path fill='var(--whitishPurple)' transform={'translate('+(zoomPositions[0]+8)+' '+(zoomPositions[1]+1)+') scale(-1.12, 0.95)'} strokeWidth='0' d='M 3 5 A 0.1 0.1 0 0 1 4 4 Q 4 2.8 5 3 A 0.1 0.1 0 0 1 7 3 Q 8 3 8 4 Q 9 4 9 5 Z' />
            <path fill='transparent' transform='translate(8 1) scale(-1.12, 0.95)' strokeWidth='0.08' stroke='var(--purplishBlue)' d='M 3 5 A 0.1 0.1 0 0 1 4 4 Q 4 2.8 5 3 A 0.1 0.1 0 0 1 7 3 Q 8 3 8 4 Q 9 4 9 5' />
            <path fill='var(--whitishPurple' strokeWidth='0' d='M -4 5 L 15 5 L 15 10 L -4 10 Z' />
          </svg>
          <div className='text-introduction-work-container'>
            <div className='JW-work-flex-title' data-aos='fade-down'>The Story So Far<span className='JW-work-flex-dot'>.</span></div><br/>
            <div className='JW-work-text-column' data-aos='fade-up'>
              In sign language, my name represents vigorousness, a fitting symbol for a life marked by movement and resilience. Growing up in Washington, 
              I lived in a mecca of tech and innovation.
            </div>
            <br/>
            <div className='JW-work-text-column' data-aos='fade-up'>
              My journey into data science and website development began during my time at the Johns Hopkins graduate program for applied economics. This exposure 
              sparked a deep interest in these fields, leading me to dedicate my studies and career to mastering them.
            </div> 
            <br/>
            <div className='JW-work-text-column' data-aos='fade-up'>
              Returning to Washington, I immersed myself in the world of data science, eventually joining the National Deaf Center on Postsecondary Outcomes. There, 
              I took on the challenge of building a data dashboard and conducting comprehensive qualitative and quantitative analyses, leveraging my skills to make a 
              meaningful impact.
            </div>    
            <br/>
            <div className='JW-work-text-column' data-aos='fade-up'>
              Recently, I established JW TechData LLC, continuing my passion for creating impactful solutions in the tech industry. 
            </div>           
          </div>
        </div>
        <div className = 'flex-direction-row'>
          <div className='text-introduction-work-container'>
            <div className='JW-work-flex-title' data-aos='fade-down'>Harnessing Data for Real-World Impact<span className='JW-work-flex-dot'>.</span></div><br/>
            <div className='JW-work-text-column' data-aos='fade-up'>
              Various survey techniques and data collection strategies—beyond number crunching and coding—pique my curiosity. I have been fascinated 
              by the stories data can tell, and I enjoy tackling challenges such as analyzing videos, images, and texts. At my core, I am always a student 
              eager to learn new techniques and methodologies to better understand and interpret data. My goal is to productionalize prototypes that will 
              have a positive impact on communities.
            </div>
            <br/>
            <div className='JW-work-text-column' data-aos='fade-up'>
              Not only do I have this interest, but I am also keen on the intersection of statistics and technology. Frustrated by the misuse of data in superficial 
              analytics, I am currently exploring ways that statistical methods and AI can be used to address real-world problems, such as improving economic outcomes, 
              closing business gaps, and enhancing social science research.
            </div>             
          </div>
          <svg viewBox='-2 -5 13 11.5' className = 'work-size-svg' data-aos='fade-down'>
            <defs>
              <pattern id="svg-pattern" x="0" y="0" width="21" height="21" patternUnits="userSpaceOnUse" patternTransform="translate(11, 11) rotate(15) skewX(0) scale(0.01)" alt='A purple machine icon featuring spinning gears that react to mouse movement.'>
                <svg width="10" height="10" viewBox="0 0 20 20">
                  <g fill="var(--transDarkerBlue)" opacity="1">
                    <circle cx="10" cy="10" r="10">
                    </circle>
                  </g>
                </svg>
              </pattern>
            </defs>
            <path fill='url(#svg-pattern)' transform={'translate('+(zoomPositions[0])+' '+zoomPositions[1]+')'} d='M -2 2 A 0.1 0.1 0 0 0 9.5 1 A 0.1 0.1 0 0 0 -2 2 Z'/>
            <path fill='var(--whitishPurple)' strokeWidth='0' d='M -4 3 L 15 3 L 15 10 L -4 10 Z' />
            <path stroke='var(--purplishBlue)' strokeWidth='0.1' d='M -1.7 3 L 9.7 3 L -1.7 3 Z' />
            <path fill='var(--whitishPurple)' stroke='var(--whitishPurple)' strokeWidth='0.5' d='M 0 -0.5 Q 0 -1 0.5 -1 L 2.5 -1 Q 3 -1 3 -0.5 L 3 0.3 L 5.6 0.3 Q 6.1 0.3 6.1 0.8 L 6.1 2.5 Q 6.1 3.5 4 3.5 Q 0 3.5 0 2 Z M 1 3 L 0.5 4 L 1 3 Z M 5 3 L 5.5 4 L 5 3 Z M 6.2 1.1 A 0.1 0.1 0 0 1 6.4 1.1 L 6.4 2.1 A 0.1 0.1 0 0 1 6.2 2.1 Z M 6.5 1.1 A 0.1 0.1 0 0 1 6.7 1.1 L 6.7 2.1 A 0.1 0.1 0 0 1 6.5 2.1 Z M 6.8 1.1 A 0.1 0.1 0 0 1 7 1.1 L 7 2.1 A 0.1 0.1 0 0 1 6.8 2.1 Z M 0.7 -2 L 1 -1 L 2 -1 L 2.3 -2 Q 1.5 -2.4 0.7 -2 Z M 7.9 0.7 A 0.1 0.1 0 0 0 7.9 2.4 Q 8.3 1.5 7.9 0.7 Z' /> 
            <path fill= 'transparent' stroke='var(--purplishBlue)' strokeWidth='0.1' d='M 1 3 L 0.5 4 L 1 3 Z M 5 3 L 5.5 4 L 5 3 Z M 0.7 -2 L 1 -1 L 2 -1 L 2.3 -2 Q 1.5 -2.4 0.7 -2 Z M 2.1 -1.9 Q 1.5 -2.2 0.9 -1.9 Q 1.5 -1.8 2.1 -1.9 Z' />
            <path fill = 'var(--whitishPurple)' strokeWidth='0' d='M 0 -0.5 Q 0 -1 0.5 -1  L 2.5 -1 Q 3 -1 3-0.5 L 3 0.3 L 5.6 0.3 Q 6.1 0.3 6.1 0.8 L 6.1 2.5 Q 6.1 3.5 4 3.5 Q 0 3.5 0 2 Z' />
            <path fill = 'var(--transBlue)' transform={'translate('+(zoomPositions[0])+' '+zoomPositions[1]+')'} strokeWidth='0' d='M 0 -0.5 Q 0 -1 0.5 -1  L 2.5 -1 Q 3 -1 3-0.5 L 3 0.3 L 5.6 0.3 Q 6.1 0.3 6.1 0.8 L 6.1 2.5 Q 6.1 3.5 4 3.5 Q 0 3.5 0 2 Z' />
            <path fill = 'transparent' stroke='var(--purplishBlue)' strokeWidth='0.1' d='M 0 -0.5 Q 0 -1 0.5 -1 L 2.5 -1 Q 3 -1 3 -0.5 L 3 0.3 L 5.6 0.3 Q 6.1 0.3 6.1 0.8 L 6.1 2.5 Q 6.1 3.5 4 3.5 Q 0 3.5 0 2 Z M 7.9 0.7 A 0.1 0.1 0 0 0 7.9 2.4 Q 8.3 1.5 7.9 0.7 Z M 7.9 0.7 Z' />
            <path fill = 'var(--whitishPurple)' transform={'translate('+(zoomPositions[0])+' '+zoomPositions[1]+')'} strokeWidth='0' d='M 0.5 -0.5 A 0.1 0.1 0 0 0 0.8 -0.5 A 0.1 0.1 0 0 0 0.5 -0.5 Z M 1.05 -0.5 A 0.1 0.1 0 0 0 1.35 -0.5 A 0.1 0.1 0 0 0 1.05 -0.5 Z M 1.6 -0.5 A 0.1 0.1 0 0 0 1.9 -0.5 A 0.1 0.1 0 0 0 1.6 -0.5 Z M 2.15 -0.5 A 0.1 0.1 0 0 0 2.45 -0.5 A 0.1 0.1 0 0 0 2.15 -0.5 Z' />
            <path fill = 'transparent' transform={'translate('+(zoomPositions[0])+' '+zoomPositions[1]+')'} stroke='var(--purplishBlue)' strokeWidth='0.05' d='M 0.5 -0.5 A 0.1 0.1 0 0 0 0.8 -0.5 A 0.1 0.1 0 0 0 0.5 -0.5 Z M 1.05 -0.5 A 0.1 0.1 0 0 0 1.35 -0.5 A 0.1 0.1 0 0 0 1.05 -0.5 Z M 1.6 -0.5 A 0.1 0.1 0 0 0 1.9 -0.5 A 0.1 0.1 0 0 0 1.6 -0.5 Z M 2.15 -0.5 A 0.1 0.1 0 0 0 2.45 -0.5 A 0.1 0.1 0 0 0 2.15 -0.5 Z' />
            <path fill = 'var(--purplishBlue)' style={{transformBox: 'fill-box', transformOrigin: 'center'}}  transform={'translate(1.48 -1.75) scale(0.2) rotate('+rotateGear+')'} stroke='0' d = 'M 6 3.404 v -0.808 c -0.4128 -0.1467 -0.6735 -0.188 -0.8047 -0.5048 v -0.0003 c -0.1318 -0.3177 0.025 -0.5335 0.2117 -0.9267 l -0.5713 -0.5713 c -0.3902 0.1855 -0.6082 0.3438 -0.9267 0.2117 h -0.0003 c -0.3172 -0.1315 -0.3588 -0.394 -0.5048 -0.8047 h -0.808 c -0.1455 0.4088 -0.1872 0.673 -0.5048 0.8047 h -0.0003 c -0.3177 0.132 -0.533 -0.0245 -0.9267 -0.2117 l -0.5713 0.5713 c 0.1862 0.392 0.3438 0.6085 0.2117 0.9267 c -0.1318 0.3177 -0.396 0.3595 -0.8047 0.505 v 0.808 c 0.408 0.145 0.673 0.1872 0.8047 0.5048 c 0.1325 0.3205 -0.0285 0.5415 -0.2117 0.9267 l 0.5713 0.5715 c 0.3905 -0.1857 0.6085 -0.3438 0.9267 -0.2117 h 0.0003 c 0.3175 0.1315 0.359 0.3947 0.5048 0.8047 h 0.808 c 0.1455 -0.409 0.1875 -0.6725 0.5068 -0.8055 h 0.0003 c 0.3155 -0.131 0.53 0.0253 0.9245 0.2127 l 0.5713 -0.5715 c -0.186 -0.3907 -0.3438 -0.6082 -0.212 -0.9265 c 0.1318 -0.3177 0.397 -0.36 0.8053 -0.5052 z M 1.25 3 A 0.25 0.25 90 0 1 4.75 3 A 0.25 0.25 90 0 1 1.25 3 Z' />
            <path fill = 'var(--purplishBlue)' style={{transformBox: 'fill-box', transformOrigin: 'center'}}  transform={'translate(0.9 -0.85) scale(0.17) rotate('+(-1*rotateGear)+')'} stroke='0' d = 'M 6 3.404 v -0.808 c -0.4128 -0.1467 -0.6735 -0.188 -0.8047 -0.5048 v -0.0003 c -0.1318 -0.3177 0.025 -0.5335 0.2117 -0.9267 l -0.5713 -0.5713 c -0.3902 0.1855 -0.6082 0.3438 -0.9267 0.2117 h -0.0003 c -0.3172 -0.1315 -0.3588 -0.394 -0.5048 -0.8047 h -0.808 c -0.1455 0.4088 -0.1872 0.673 -0.5048 0.8047 h -0.0003 c -0.3177 0.132 -0.533 -0.0245 -0.9267 -0.2117 l -0.5713 0.5713 c 0.1862 0.392 0.3438 0.6085 0.2117 0.9267 c -0.1318 0.3177 -0.396 0.3595 -0.8047 0.505 v 0.808 c 0.408 0.145 0.673 0.1872 0.8047 0.5048 c 0.1325 0.3205 -0.0285 0.5415 -0.2117 0.9267 l 0.5713 0.5715 c 0.3905 -0.1857 0.6085 -0.3438 0.9267 -0.2117 h 0.0003 c 0.3175 0.1315 0.359 0.3947 0.5048 0.8047 h 0.808 c 0.1455 -0.409 0.1875 -0.6725 0.5068 -0.8055 h 0.0003 c 0.3155 -0.131 0.53 0.0253 0.9245 0.2127 l 0.5713 -0.5715 c -0.186 -0.3907 -0.3438 -0.6082 -0.212 -0.9265 c 0.1318 -0.3177 0.397 -0.36 0.8053 -0.5052 z M 1.25 3 A 0.25 0.25 90 0 1 4.75 3 A 0.25 0.25 90 0 1 1.25 3 Z' />
            <path fill = 'var(--purplishBlue)' style={{transformBox: 'fill-box', transformOrigin: 'center'}}  transform={'translate(2.1 -0.85) scale(0.17) rotate('+(-1*rotateGear)+')'} stroke='0' d = 'M 6 3.404 v -0.808 c -0.4128 -0.1467 -0.6735 -0.188 -0.8047 -0.5048 v -0.0003 c -0.1318 -0.3177 0.025 -0.5335 0.2117 -0.9267 l -0.5713 -0.5713 c -0.3902 0.1855 -0.6082 0.3438 -0.9267 0.2117 h -0.0003 c -0.3172 -0.1315 -0.3588 -0.394 -0.5048 -0.8047 h -0.808 c -0.1455 0.4088 -0.1872 0.673 -0.5048 0.8047 h -0.0003 c -0.3177 0.132 -0.533 -0.0245 -0.9267 -0.2117 l -0.5713 0.5713 c 0.1862 0.392 0.3438 0.6085 0.2117 0.9267 c -0.1318 0.3177 -0.396 0.3595 -0.8047 0.505 v 0.808 c 0.408 0.145 0.673 0.1872 0.8047 0.5048 c 0.1325 0.3205 -0.0285 0.5415 -0.2117 0.9267 l 0.5713 0.5715 c 0.3905 -0.1857 0.6085 -0.3438 0.9267 -0.2117 h 0.0003 c 0.3175 0.1315 0.359 0.3947 0.5048 0.8047 h 0.808 c 0.1455 -0.409 0.1875 -0.6725 0.5068 -0.8055 h 0.0003 c 0.3155 -0.131 0.53 0.0253 0.9245 0.2127 l 0.5713 -0.5715 c -0.186 -0.3907 -0.3438 -0.6082 -0.212 -0.9265 c 0.1318 -0.3177 0.397 -0.36 0.8053 -0.5052 z M 1.25 3 A 0.25 0.25 90 0 1 4.75 3 A 0.25 0.25 90 0 1 1.25 3 Z' />
            <path fill = 'var(--purplishBlue)' stroke='0' d = 'M 6.2 1.1 A 0.1 0.1 0 0 1 6.4 1.1 L 6.4 2.1 A 0.1 0.1 0 0 1 6.2 2.1 Z M 6.5 1.1 A 0.1 0.1 0 0 1 6.7 1.1 L 6.7 2.1 A 0.1 0.1 0 0 1 6.5 2.1 Z M 6.8 1.1 A 0.1 0.1 0 0 1 7 1.1 L 7 2.1 A 0.1 0.1 0 0 1 6.8 2.1 Z M 7.8 0.8 Q 7.6 1.5 7.8 2.3 Q 8.1 1.5 7.8 0.8 Z M 2.1 -1.9 Q 1.5 -2.2 0.9 -1.9 Q 1.5 -1.8 2.1 -1.9 Z' />
          </svg>
        </div>
        <div className='text-introduction-about-myself-container'>
          <div id ='withSvg'>
            <svg viewBox='0 0 10 10' className = 'work-size-svg' data-aos='fade-down'>
              <defs>
                <pattern id="svg-pattern" x="0" y="0" width="21" height="21" patternUnits="userSpaceOnUse" patternTransform="translate(11, 11) rotate(15) skewX(0) scale(0.01)">
                  <svg width="10" height="10" viewBox="0 0 20 20">
                    <g fill="var(--transDarkerBlue)" opacity="1">
                      <circle cx="10" cy="10" r="10">
                      </circle>
                    </g>
                  </svg>
                </pattern>
              </defs>
              <path fill='url(#svg-pattern)' transform={'translate('+(zoomPositions[0]+1)+' '+zoomPositions[1]+')'} d='M 1 1 L 1 9 L 8.9 9 L 8.9 1 Z'/>
            </svg>
            <img src={picture_of_me} alt='This is me: a man with white skin and brown hair, broad shoulders, sitting with my arms on my knees in front of a rocky background, wearing a short-sleeve blue button-up shirt with a floral pattern, captured in purplescale, with a soft smile.'/>
          </div>
          <div>
            <h1>Hi, my name is Jonah.</h1>
            <p>
              Feel free to contact me through LinkedIn and share what kind of problem you are trying to solve. 
            </p>
            <Link
              className='WorkLinkButton'
              id='about-my-work'
              to='https://www.linkedin.com/in/jonah-winninghoff-ms-2b9b167b/' 
              aria-label='My LinkedIn Profile page'
              data-aos='zoom-in-up'
              target="_blank" 
              rel="noopener noreferrer"
            >
              <span className='WorkLinkButton_text JW-work-patterns'>LinkedIN</span>
              <span className='work-drop-background' id='add-transition' style={{transform: 'translate('+(-20*zoomPositions[0])+'px,'+(-20*zoomPositions[1])+'px)'}}>LinkedIN</span>
            </Link> 
          </div>
        </div>
        <br/><br/><br/><br/><br/><br/><br/><br/>
      </div>
    </div>
  )
}