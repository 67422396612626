import './App.css';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { NavBar } from './components/NavBar';
import { HomePage } from './Pages/home.js';
import { AboutMyWork } from './Pages/aboutmywork.js';
import { AboutMe } from './Pages/aboutme.js';

import { NDCDashboard, ParkinsonDisease, Battleship } from './Pages/portfolios.js';

//import { UnderConstruction } from './Pages/underConstruction.js';
import { Error404 } from './Pages/404page.js'

import ScrollToTop from './components/scrollToTop.js';

import { Footer } from './components/Footer.js'; 

function App() {
  return (
    <>
      <Router>
        <ScrollToTop/>
        <NavBar/>
        <Routes>
          <Route exact path='/' element={<HomePage />} />

          <Route exact path='/aboutme' element={<AboutMe />} />
          <Route exact path='/aboutmywork' element={<AboutMyWork />} />
          <Route exact path='/Dashboard' element={<NDCDashboard />} />
          <Route exact path='/ParkinsonAndDepression' element={<ParkinsonDisease />} />
          <Route exact path='/FingerspellBattleship' element={<Battleship />} />
          <Route exact path='/*' element={<Error404 />} />
        </Routes>
        <Footer/>
        <div className='copyright-text'>© {new Date().getFullYear()}. JW | TechData LLC <div className='copyright-dash'/></div>
      </Router>
    </>
  );
}

export default App;
