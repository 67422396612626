import React, { useEffect, useState, useRef } from 'react';
import battleship from '../assets/battleship.mp4'
import './portfolio.css';

import "aos/dist/aos.css";
import AOS from "aos";
import { Link } from 'react-router-dom';

export function NDCDashboard(){
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

    // Hover Effect
    const inputSvgEffect = useRef(null);
    const buttonRef = useRef(null);
    const [positions, setPositions] = useState([0,0]);
    function svgHoverEffect(event){
      const dist = 0.1

      let y = event.clientY;
      let x = event.clientX;
      let array = inputSvgEffect.current.getBoundingClientRect();
      let left = (array.left+array.right)/2
      let top = (array.top+array.bottom)/2
  
      let input_x
      if((x-left) > 0){
        input_x = dist*Math.log(x-left)
      }else if((x-left) === 0){
        input_x = 0
      }else{
        input_x = -dist*Math.log(Math.abs(x-left))
      }
  
      let input_y
      if((y-top) > 0){
        input_y = dist*Math.log(y-top)
      }else if((y-top) === 0){
        input_y = 0
      }else{
        input_y = -dist*Math.log(Math.abs(y-top))
      }
  
      setPositions([input_x,input_y])
    };

  return(
    <div className='introduction-container'>
      <div className='portfolio-body' onMouseMove={svgHoverEffect} ref={buttonRef}>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <div className='text-portfolio-container'>
          <h1 className='JW-portfolio-title' data-aos='fade-down' role = 'main'>NDC Data Dashboard</h1><br/>
          <div className='JW-portfolio-text' data-aos='fade-up'>
            <svg viewBox='0 -12 20 12' ref={inputSvgEffect} data-aos='flip-left'>
              <defs>
                <pattern id="svg-pattern" x="0" y="0" width="21" height="21" patternUnits="userSpaceOnUse" patternTransform="translate(11, 11) rotate(15) skewX(0) scale(0.01)">
                  <svg width="10" height="10" viewBox="0 0 20 20">
                    <g fill="var(--purplishBlue)" opacity="1">
                      <circle cx="10" cy="10" r="10">
                      </circle>
                    </g>
                  </svg>
                </pattern>
              </defs>
              <path fill='var(--transBlue)' transform={'translate('+positions[0]+' '+positions[1]+') scale(0.95,0.95)'} strokeWidth='0' d='M 2 0 L 9 0 L 9 -10 C 9 -11, 8 -11, 8 -11 L 3 -11 C 3 -11, 2 -11, 2 -10 Z' />
              <path fill='url(#svg-pattern)' transform={'translate('+positions[0]+' '+positions[1]+') scale(0.95,0.95)'} strokeWidth='0' d='M 11 0 L 18 0 L 18 -6 C 18 -7, 17 -7, 17 -7 L 12 -7 C 12 -7, 11 -7, 11 -6 Z'></path>
              <path fill='transparent' stroke='var(--purplishBlue)' strokeWidth='0.1px' d='M 0 0 L 20 0 L 0 0 Z M 2 0 L 9 0 L 9 -10 C 9 -11, 8 -11, 8 -11 L 3 -11 C 3 -11, 2 -11, 2 -10 Z M 11 0 L 18 0 L 18 -6 C 18 -7, 17 -7, 17 -7 L 12 -7 C 12 -7, 11 -7, 11 -6 Z'/>
            </svg><br/><br/><br/><br/>
            As a data analyst for the National Deaf Center on Postsecondary Outcomes (NDC), 
            I applied advanced statistical techniques and data visualization to support their 
            mission of enhancing educational and employment success for deaf individuals. I 
            collaborated with the NDC Research and Data team on projects such as transitioning 
            from static PDF reports to an interactive, easily updatable data dashboard.
            <br/><br/>
            <b className='theB'>Objective</b><br/>
            Transition NDC's widely cited PDF reports into an accessible, updatable data 
            dashboard that meets user needs, efficiently processes 20GB of data, and automates 
            PDF report generation.        
            <br/><br/>
            <b className='theB'>Challenge</b>
            <ul>
              <li>
                Transition from static PDF reports (with a 2019 report cited 200+ times) to an interactive dashboard.
              </li>
              <li>
                Develop a scalable, cost-effective solution that aligns with NDC's high-profile data dissemination needs.
              </li>
              <li>
                Ensure accessibility, simplicity, and high performance on limited memory.
              </li>
            </ul>
            <b className='theB'>Challenge</b>
            <ul>
              <li>
                <b>Technology Selection:</b> Implemented an R-based data pipeline, Express.js + Node.js + React.js for the dashboard, GitHub for version control and CI/CD, and Cloudways for deployment at a cost of $28 monthly.
              </li>
              <li>
                <b>Data Processing:</b> Used Monte Carlo simulations, direct standard errors, and various tests to analyze statistical inferences with weighted survey data, generating state and national PDF reports automatically.
              </li>
              <li>
                <b>Cross-functional Collaboration:</b> Worked closely with NDC’s team to enhance UI/UX and accessibility.
              </li>
            </ul>
            <b className='theB'>Results</b>
            <ul>
              <li>
                <b>High Engagement:</b> The dashboard received 15,000+ queries, making it NDC's most visited page.
              </li>
              <li>
                <b>Efficiency:</b> Data was updated successfully for four consecutive years with minimal intervention, including automatic generation of 50 state reports.
              </li>
              <li>
                <b>Funding Success:</b> Instrumental in securing over $2 million in grants, with adoption by organizations like the National Association of the Deaf and Gallaudet University.
              </li>
            </ul><br/><br/>
            <Link
              className='LinkButton'
              to='https://nationaldeafcenter.org/dashboard'
              target='_blank'
              rel="noopener noreferrer"
              aria-label='Link to NDC Dashboard'
              data-aos='zoom-in-up'
            >
              <span className='LinkButton_text JW-patterns'>Explore Dashboard</span>
              <span className='drop-background' style={{transform: 'translate('+positions[0]+'px,'+positions[1]+'px)'}}>Explore Dashboard</span>
            </Link>
          </div>      
        </div>
      </div>
    </div>
  )
}

export function ParkinsonDisease(){
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

    // Hover Effect
    const inputSvgEffect = useRef(null);
    const buttonRef = useRef(null);
    const [positions, setPositions] = useState([0,0]);
    function svgHoverEffect(event){
      const dist = 0.1

      let y = event.clientY;
      let x = event.clientX;
      let array = inputSvgEffect.current.getBoundingClientRect();
      let left = (array.left+array.right)/2
      let top = (array.top+array.bottom)/2
  
      let input_x
      if((x-left) > 0){
        input_x = dist*Math.log(x-left)
      }else if((x-left) === 0){
        input_x = 0
      }else{
        input_x = -dist*Math.log(Math.abs(x-left))
      }
  
      let input_y
      if((y-top) > 0){
        input_y = dist*Math.log(y-top)
      }else if((y-top) === 0){
        input_y = 0
      }else{
        input_y = -dist*Math.log(Math.abs(y-top))
      }
  
      setPositions([input_x,input_y])
    };

  return(
    <div className='introduction-container'>
      <div className='portfolio-body' onMouseMove={svgHoverEffect} ref={buttonRef}>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <div className='text-portfolio-container'>
          <h1 className='JW-portfolio-title' data-aos='fade-down' role = 'main'>Parkinson and Depression</h1><br/>
          <div className='JW-portfolio-text' data-aos='fade-up'>
            <svg viewBox='0 -12 20 12' ref={inputSvgEffect} data-aos='flip-left'>
              <defs>
                <pattern id="svg-pattern" x="0" y="0" width="21" height="21" patternUnits="userSpaceOnUse" patternTransform="translate(11, 11) rotate(15) skewX(0) scale(0.01)">
                  <svg width="10" height="10" viewBox="0 0 20 20">
                    <g fill="var(--purplishBlue)" opacity="1">
                      <circle cx="10" cy="10" r="10">
                      </circle>
                    </g>
                  </svg>
                </pattern>
              </defs>
              <path fill='var(--transBlue)' transform={'translate('+positions[0]+' '+positions[1]+') scale(0.95,0.95)'} strokeWidth='0' d='M 8 -3.5 A 1 1 0 0 0 13 -3.5 A 1 1 0 0 0 8 -3.5 Z' />
              <path fill='var(--transBlue)' transform={'translate('+positions[0]+' '+positions[1]+') scale(0.95,0.95)'} strokeWidth='0' d='M 12 -8.7 A 1 1 0 0 0 17 -8.7 A 1 1 0 0 0 12 -8.7 Z' />
              <path fill='url(#svg-pattern)' transform={'translate('+positions[0]+' '+positions[1]+') scale(0.95,0.95)'} strokeWidth='0' d='M 3 -8.5 A 1 1 0 0 0 8 -8.5 A 1 1 0 0 0 3 -8.5 Z'></path>
              <path fill='transparent' stroke='var(--purplishBlue)' strokeWidth='0.1px' d='M 3 -8.5 A 1 1 0 0 0 8 -8.5 A 1 1 0 0 0 3 -8.5 Z M 8 -3.5 A 1 1 0 0 0 13 -3.5 A 1 1 0 0 0 8 -3.5 Z M 12 -8.7 A 1 1 0 0 0 17 -8.7 A 1 1 0 0 0 12 -8.7 Z M 5.5 -6 L 8.05 -4 L 5.5 -6 Z M 12.95 -4 L 14.5 -6.2 L 12.95 -4 Z M 8 -8.5 L 12 -8.7 L 8 -8.5 Z'/>
            </svg><br/><br/><br/><br/>
            As a statistician for the Rochester Institute of Technology, I provided consulting 
            services with 10+ professors and directors and professional development with researchers. 
            I conducted advanced statistical analyses using Python, R, and SPSS. At that time, I
            worked with a professor and researcher to analyze Parkinson's disease and depression.
            <br/><br/>
            <b className='theB'>Objective</b><br/>
            Determine if the presence of Parkinson's disease (PD) with depression is associated with 
            increased depression severity, structure causal-effect relationships surrounding PD, and 
            identify magnitudes of motor and nonmotor symptoms (NMS).      
            <br/><br/>
            <b className='theB'>Challenge</b>
            <ul>
              <li>
                Depression is not directly observed, complicating accurate measurement and analysis.
              </li>
              <li>
                Unlike Alzheimer's disease, no gold-standard directed acyclic graph (DAG) is established for Parkinson's disease.
              </li>
              <li>
                Each depressive symptom varies in severity and often overlaps with others, possibly impeding the analysis.
              </li>
            </ul>
            <b className='theB'>My Approach</b>
            <ul>
              <li>
                <b>Collaboration with Subject-Matter Expert:</b> Shared need-to-know concepts of confounding and directed acyclic graph and worked together to develop DAG based on priori knowledge and literature review.
              </li>
              <li>
                <b>Item Response Theory:</b> Applied 2-Parameter Logistic (2-PL) model with a Structural Equation Model approach (a statistical technique that tests and verifies relationships between variables in a complex model), including differential item functioning (DIF).
              </li>
              <li>
                <b>Tobit Model:</b> Implemented a Tobit model based on the DAG, set up according to how the 2-PL model's factor scores were formed, and interpreted independent variables of interest using Average Treatment Effects (ATE).
              </li>
            </ul>
            <b className='theB'>Results</b>
            <ul>
              <li>
                <b>Support for NMS Research:</b> With 30-40% of people with PD affected by NMS and most treatments focused on motor impairments, depression has a more severe impact on individuals with PD compared to those without.
              </li>
              <li>
                <b>Key Findings:</b> Based on the DIF analysis, the question regarding <i>Dropped interests and activities</i> indicated the highest severity of depression. Furthermore, the Tobit model's (ATE) analysis showed that an increase in the average number of hours spent walking decreases depression severity.
              </li>
              <li>
                <b>Further Directions:</b> The DAG needs verification using a new dataset with two causal discovery algorithms (fast greedy equivalence search and fast causal inference).
              </li>
            </ul><br/><br/>
            <Link
              className='LinkButton'
              to='https://doi.org/10.1101/2024.05.29.24308144'
              target='_blank'
              rel="noopener noreferrer"
              aria-label='Link to my work'
              data-aos='zoom-in-up'
            >
              <span className='LinkButton_text JW-patterns'>Read More</span>
              <span className='drop-background' style={{transform: 'translate('+positions[0]+'px,'+positions[1]+'px)'}}>Read More</span>
            </Link>
          </div>      
        </div>
      </div>
    </div>
  )
}

export function Battleship(){
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

    // Hover Effect
    const inputSvgEffect = useRef(null);
    const buttonRef = useRef(null);
    const [positions, setPositions] = useState([0,0]);
    function svgHoverEffect(event){
      const dist = 0.1

      let y = event.clientY;
      let x = event.clientX;
      let array = inputSvgEffect.current.getBoundingClientRect();
      let left = (array.left+array.right)/2
      let top = (array.top+array.bottom)/2
  
      let input_x
      if((x-left) > 0){
        input_x = dist*Math.log(x-left)
      }else if((x-left) === 0){
        input_x = 0
      }else{
        input_x = -dist*Math.log(Math.abs(x-left))
      }
  
      let input_y
      if((y-top) > 0){
        input_y = dist*Math.log(y-top)
      }else if((y-top) === 0){
        input_y = 0
      }else{
        input_y = -dist*Math.log(Math.abs(y-top))
      }
  
      setPositions([input_x,input_y])
    };

  return(
    <div className='introduction-container'>
      <div className='portfolio-body' onMouseMove={svgHoverEffect} ref={buttonRef}>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <div className='text-portfolio-container'>
          <h1 className='JW-portfolio-title' data-aos='fade-down' role = 'main'>Fingerspelling Battleship</h1><br/>
          <div className='JW-portfolio-text' data-aos='fade-up'>
            <svg viewBox='0 -11.5 20 12.5' ref={inputSvgEffect} data-aos='flip-left'>
              <defs>
                <pattern id="svg-pattern" x="0" y="0" width="21" height="21" patternUnits="userSpaceOnUse" patternTransform="translate(11, 11) rotate(15) skewX(0) scale(0.01)">
                  <svg width="10" height="10" viewBox="0 0 20 20">
                    <g fill="var(--purplishBlue)" opacity="1">
                      <circle cx="10" cy="10" r="10">
                      </circle>
                    </g>
                  </svg>
                </pattern>
              </defs>
              <path fill='var(--transBlue)' transform={'translate('+positions[0]+' '+positions[1]+') scale(0.95,0.95)'} strokeWidth='0' d='M 8.5 -2 Q 8 -2 8 -1.5 L 8 -0.5 Q 8 0 8.5 0 L 11.5 0 Q 12 0 12 -0.5 L 12 -1.5 Q 12 -2 11.5 -2 Z' />
              <path fill='url(#svg-pattern)' transform={'translate('+positions[0]+' '+positions[1]+') scale(0.95,0.95)'} strokeWidth='0' d='M 12.6912 -9.109 c -0.3119 -0.0634 -0.6171 0.1377 -0.6809 0.45 l -0.2748 1.3459 c -0.0103 0.0507 -0.0584 0.0845 -0.1096 0.0771 c -0.0512 -0.0074 -0.0878 -0.0533 -0.0833 -0.1049 l 0.2086 -2.4501 c 0.0297 -0.349 -0.2292 -0.6562 -0.5782 -0.6858 c -0.3488 -0.0297 -0.6562 0.2292 -0.6858 0.5782 l -0.1777 2.0866 c -0.0046 0.0547 -0.0515 0.0959 -0.1065 0.0936 c -0.0547 -0.0023 -0.0981 -0.0474 -0.0981 -0.1023 V -10.3725 C 10.1049 -10.7229 9.8208 -11.0069 9.4705 -11.0069 s -0.6344 0.284 -0.6344 0.6344 v 2.5116 c 0 0.045 -0.035 0.0821 -0.0798 0.0846 c -0.0449 0.0026 -0.0839 -0.0302 -0.0892 -0.0748 l -0.2403 -2.039 c -0.041 -0.3479 -0.3563 -0.5968 -0.7043 -0.5558 c -0.3479 0.041 -0.5967 0.3563 -0.5558 0.7043 L 7.6622 -5.538 c 0.0064 0.0542 -0.0225 0.1064 -0.0718 0.1297 c -0.0492 0.0233 -0.1081 0.0126 -0.1458 -0.0268 l -1.0606 -1.1016 c -0.2429 -0.2524 -0.6445 -0.26 -0.8969 -0.017 c -0.2524 0.2429 -0.26 0.6445 -0.017 0.8969 l 2.5168 2.6141 c 0.2903 0.3812 0.7488 0.6281 1.264 0.6281 h 1.5212 c 0.9264 0 1.68 -0.7537 1.68 -1.68 c 0 -0.6366 0.0642 -1.2716 0.1915 -1.8953 l 0.4978 -2.4381 C 13.2049 -8.7405 13.0034 -9.0453 12.6912 -9.109 z'></path>
              <path fill='transparent' stroke='var(--purplishBlue)' strokeWidth='0.1px' d='M 12.6912 -9.109 c -0.3119 -0.0634 -0.6171 0.1377 -0.6809 0.45 l -0.2748 1.3459 c -0.0103 0.0507 -0.0584 0.0845 -0.1096 0.0771 c -0.0512 -0.0074 -0.0878 -0.0533 -0.0833 -0.1049 l 0.2086 -2.4501 c 0.0297 -0.349 -0.2292 -0.6562 -0.5782 -0.6858 c -0.3488 -0.0297 -0.6562 0.2292 -0.6858 0.5782 l -0.1777 2.0866 c -0.0046 0.0547 -0.0515 0.0959 -0.1065 0.0936 c -0.0547 -0.0023 -0.0981 -0.0474 -0.0981 -0.1023 V -10.3725 C 10.1049 -10.7229 9.8208 -11.0069 9.4705 -11.0069 s -0.6344 0.284 -0.6344 0.6344 v 2.5116 c 0 0.045 -0.035 0.0821 -0.0798 0.0846 c -0.0449 0.0026 -0.0839 -0.0302 -0.0892 -0.0748 l -0.2403 -2.039 c -0.041 -0.3479 -0.3563 -0.5968 -0.7043 -0.5558 c -0.3479 0.041 -0.5967 0.3563 -0.5558 0.7043 L 7.6622 -5.538 c 0.0064 0.0542 -0.0225 0.1064 -0.0718 0.1297 c -0.0492 0.0233 -0.1081 0.0126 -0.1458 -0.0268 l -1.0606 -1.1016 c -0.2429 -0.2524 -0.6445 -0.26 -0.8969 -0.017 c -0.2524 0.2429 -0.26 0.6445 -0.017 0.8969 l 2.5168 2.6141 c 0.2903 0.3812 0.7488 0.6281 1.264 0.6281 h 1.5212 c 0.9264 0 1.68 -0.7537 1.68 -1.68 c 0 -0.6366 0.0642 -1.2716 0.1915 -1.8953 l 0.4978 -2.4381 C 13.2049 -8.7405 13.0034 -9.0453 12.6912 -9.109 z M 8.5 -2 Q 8 -2 8 -1.5 L 8 -0.5 Q 8 0 8.5 0 L 11.5 0 Q 12 0 12 -0.5 L 12 -1.5 Q 12 -2 11.5 -2 Z'/>
            </svg><br/><br/><br/><br/>
            As an associate data analyst and full-stack developer at Gallaudet University, I provided consulting 
            services to professors and software engineers and mentored researchers. I conducted statistical and 
            cost-benefit analyses and developed AI models using Python and R. I also developed several apps 
            in JavaScript and TypeScript, incorporating full-stack technologies. One of my notable projects was 
            <i>Fingerspelling Battleship</i>.
            <br/><br/>
            <b className='theB'>App Demo</b>
            <video className='JW-video-portfolio' src={battleship} autoPlay loop controls/><br/><br/>
            <b className='theB'>Objective</b><br/>
            Gallaudet University aimed to expand their existing sign language dataset by collecting additional data 
            on modern sign languages and fingerspelling. Despite already having one of the largest sign language datasets, 
            they sought to ensure that the data remains current and reflective of the evolving nature of sign language.     
            <br/><br/>
            <b className='theB'>Challenge</b>
            <ul>
              <li>
                The number of individuals participating in surveys across research institutes has decreased over time, slowing the data collection process due to a lack of incentive.
              </li>
              <li>
                Developing game apps to speed up data collection risks introducing gamesmanship actions that differ from real-world actions, potentially skewing the data.
              </li>
              <li>
                Limited staffing resources make it challenging to ensure the quality of sign language data prior to training, validating, and testing AI models.
              </li>
            </ul>
            <b className='theB'>My Approach</b>
            <ul>
              <li>
                <b>Technology Selection:</b> Implemented AWS S3 + Express.js + Node.js + Socket.io + React.js tech stack for the app, with GitHub for version control and CI/CD, Docker for containerization, and AWS Elastic Beanstalk for deployment at a cost of $40 monthly.
              </li>
              <li>
                <b>Quality Assessment:</b> Combined class functions with video segmentation to verify hand poses and movements corresponding to fingerspelling letters. Applied the Randomized Controlled Trials with Wizard-of-Oz experiment to evaluate the model.
              </li>
              <li>
                <b>Data Integration:</b> Tracked users using UUIDs from Okta accounts with consent, automatically uploading fingerspelling videos and metadata (device type, geolocation, session times) to AWS S3, integrated with Gallaudet's existing dataset for AI model training.
              </li>
            </ul>
            <b className='theB'>Results</b>
            <ul>
              <li>
                <b>Real-Time Fingerspelling Recognition:</b> The app can capture and analyze fingerspelling in real-time, aiding in the collection of high-quality sign language data.
              </li>
              <li>
                <b>Automated Video Uploads with Metadata:</b> User videos, along with session metadata like device type, geolocation, and timestamps, are automatically stored in AWS S3, streamlining data collection.
              </li>
              <li>
                <b>Enhanced User Engagement Through Multiplayer Gameplay:</b> The online multiplayer mode is designed to handle player interactions efficiently, including features like an idle timer, wait time management, and automatic disconnection if a server is busy, helping maintain a smooth, engaging user experience.
              </li>
            </ul>
            <i>Note: The link to this app is currently unavailable as development is still ongoing.</i>
          </div>      
        </div>
      </div>
    </div>
  )
}