import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './WhatIDo.css';
import './Introduction.css';

import "aos/dist/aos.css";
import AOS from "aos";


export function WhatIDo(){
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // Hover Effect
  const inputStripRef = useRef(null);
  const [stripPositions, setStripPositions] = useState([0,0]);
  function stripHoverEffect(event){
    let y = event.clientY;
    let x = event.clientX;

    const dist = 15;
    let input_x;
    let input_y;

    input_x = dist*Math.sin(x/window.innerWidth)
    input_y = dist*Math.sin(y/window.innerHeight)

    setStripPositions([input_x,input_y])
  };

  return(
    <div className='introduction-container'>
      <div className='whatido-body' onMouseMove={stripHoverEffect}>
        <div id = 'text-above-vertical-line'>Scroll<div id='vertical_line'/></div>
        <div className='text-container'>
          <div className='JW-title' data-aos='fade-up'>Partner Up</div>
          <br/>
          <div className='JW-text' data-aos='fade-up'>
            From end-to-end data science including data cleaning, 
            data exploration, causal inference, and AI/ML engineering, 
            to automation system including automated reporting and data visualization 
            updates, to advanced web/application packed with
            high-end database systems, I work with the people to meet or surpass
            business requirements. 
          </div>
          <br/>
          <br/>
          <Link
            className='LinkButton'
            to='/AboutMyWork' 
            aria-label='Method page'
            data-aos='zoom-in-up'
          >
            <span className='LinkButton_text JW-patterns'>About My Method</span>
            <span className='drop-background' style={{transform: 'translate('+stripPositions[0]+'px,'+stripPositions[1]+'px)'}} ref={inputStripRef}>About My Method</span>
          </Link>
        </div>
      </div>
    </div>
  )
}