import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';

import 'font-awesome/css/font-awesome.min.css';
import 'font-awesome/css/font-awesome.css';
import FontAwesome from'react-fontawesome';

export function NavBar(){
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = useCallback(() => {
    const position = window.scrollY;
    setScrollPosition(position);
  },[]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const [openClose, setOpenClose] = useState('');
  const openCloseMenu = () => {
    setOpenClose(openClose === '' ? '-active' : '')
  }

  const linkItems = [
    {
      name: 'About Me',
      path: '/AboutMe'
    },
    {
      name: 'About My Work',
      path: '/AboutMyWork'
    },
    {
      name: <><FontAwesome name='suitcase' className='navbar-fontawesome'/>Hire Me</>,
      path: 'mailto:jonah.winninghoff@gmail.com'
    }
  ]

  const mobileItems = [
    {
      name: 'Home',
      path: '/'
    },
    {
      name: 'About Me',
      path: '/AboutMe'
    },
    {
      name: 'About My Work',
      path: '/AboutMyWork'
    }
  ]


  return(
    <>
      <div className='jw-navbar' disabled={scrollPosition > 70 & openClose === '' ? true : false}>
        <Link className='jw-beacon'
          to='/' 
          onClick={()=>setOpenClose('')}
          aria-label='Home page'
          disabled={scrollPosition > 70 & openClose === '' ? true : false}
        >
          <svg viewBox="0 0 10 10" className='jw-beacon-svg' width="100%" stroke="#4A55A9" fill='transparent' strokeWidth="0.35" strokeLinejoin='round'>
              <path d = 'M 0.5 4 A 0.1 0.1 0 0 0 1.3 4 L 1.3 4 A 0.1 0.1 0 0 0 0.5 4 Z M 0.5 4 L 0.5 4 L 0.5 2.5 L 2.5 0.5 L 7 0.5 L 8 1.5 A 0.1 0.1 0 0 0 8 2.3 A 0.1 0.1 0 0 0 8 1.5 L 7 0.5 L 2.5 0.5 L 0.5 2.5 L 0.5 4 Z M 9 3.3 A 0.1 0.1 0 0 0 9 2.5 A 0.1 0.1 0 0 0 9 3.3 L 9 4.3 L 7.3 5.3 L 6.3 5.3 A 0.1 0.1 0 0 0 5.5 5.3 A 0.1 0.1 0 0 0 6.3 5.3 L 7.3 5.3 L 9 4.3 L 9 3.3 Z M 1.3 4 L 2 4 L 3.2 5.3 L 4 5.3 A 0.1 0.1 0 0 0 4.8 5.3 A 0.1 0.1 0 0 0 4 5.3 L 3.2 5.3 L 2 4 L 1.3 4 Z M 4.4 5.7 L 3.5 6.5 L 3.5 8 A 0.1 0.1 0 0 0 3.5 8.8 A 0.1 0.1 0 0 0 3.5 8 L 3.5 6.5 L 4.4 5.7 Z M 0.9 4.4 L 0.9 5.6 L 2 7.3 L 2 8.7 A 0.1 0.1 0 0 0 2 9.5 A 0.1 0.1 0 0 0 2 8.7 L 2 7.3 L 0.9 5.6 L 0.9 4.4 Z M 3.7 4.4 L 2.8 3.5 L 2.8 2.1 A 0.1 0.1 0 0 0 2.8 1.3 A 0.1 0.1 0 0 0 2.8 2.1 L 2.8 3.5 L 3.7 4.4 Z M 2.1 3.2 L 1.5 3.2 L 1.5 2.3 L 1.5 3.2 L 2.1 3.2 Z M 3.2 1.7 L 6.2 1.7 L 6.8 1.3 L 6.2 1.7 L 3.2 1.7 Z M 4 3.2 A 0.1 0.1 0 0 0 4 2.4 A 0.1 0.1 0 0 0 4 3.2 L 5 4.2 L 7.2 4.2 L 7.9 3.6 L 7.2 4.2 L 5 4.2 L 4 3.2 Z M 5 2.4 L 5.6 3 L 7 3 L 5.6 3 L 5 2.4 Z'/>
          </svg>
        </Link>
        <ul className='options-navbar'>
          {linkItems.map((value,i)=>{
            return(
              <li key={i}>
                <Link className='jw-options'
                  to={value.path}
                  aria-label={value.name}
                  disabled={scrollPosition > 70 ? true : false}
                >
                  {value.name}
                </Link>
              </li>
            )
          })}
        </ul>
        <button className={'nav-menu'+openClose} onClick={openCloseMenu} 
          aria-expanded={openClose === ''}
          aria-controls="navigation-menu"
          aria-label={openClose === '' ? "Close menu" : "Open menu"}
        >
          <ul>
            <li/>
            <li/>
            <li/>
          </ul>
        </button>
      </div>
      <div className='open-menu-from-navbar' disabled={openClose === ''}>
        <div>
          <p/>
          <a href='https://www.linkedin.com/in/jonah-winninghoff-ms-2b9b167b/' target = '_blank' rel="noopener noreferrer" aria-label="Jonah Winninghoff's LinkedIn profile"><FontAwesome name='linkedin' className='navbar-fontawesome'/></a>
          <a href='https://github.com/jonahwinninghoff' target = '_blank' rel="noopener noreferrer" aria-label="Jonah Winninghoff's GitHub profile"><FontAwesome name='github' className='navbar-fontawesome'/></a>
          <a href='mailto:jonah.winninghoff@gmail.com' aria-label="Jonah's email address"><FontAwesome name='envelope' className='navbar-fontawesome'/></a>
        </div>
        <ul>
          {mobileItems.map((value,i)=>{
            return(
              <li className='jw-options' key={i}>
                <Link 
                  className='jw-options-link'
                  onClick={()=>setOpenClose('')}
                  to={value.path}
                  aria-label={value.name}
                  disabled={scrollPosition > 70 ? true : false}
                >
                  {value.name}
              </Link>
            </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}