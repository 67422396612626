import React, { useState, useEffect, useRef } from 'react';
import './WhatIDo.css';
import './Introduction.css';

import "aos/dist/aos.css";
import AOS from "aos";

import { Link } from 'react-router-dom';

export function MyPortfolio(){
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // Hover Effect
  const inputStripRef = useRef(null);
  const [stripPositions, setStripPositions] = useState([0,0]);
  function stripHoverEffect(event){
    let y = event.clientY;
    let x = event.clientX;

    const dist = 15
    let input_x;
    let input_y;

    input_x = dist*Math.sin(x/window.innerWidth)
    input_y = dist*Math.sin(y/window.innerHeight)

    setStripPositions([input_x,input_y])
  };

  const items =[
    {
      question: 'NDC Dashboard',
      path: '/Dashboard',
      aria: 'Portfolio Page - NDC Dashboard'
    },
    {
      question: "Parkinson's and Depression", 
      path: '/ParkinsonAndDepression',
      aria: "Portfolio Page - Parkinson's and Depression"
    },
    {
      question: 'Fingerspelling Battleship',
      path: '/FingerspellBattleship',
      aria: 'Portfolio Page - Fingerspelling Battleship'
    },
    {question: 'Effects of Nursing Home Facilities',
     path: 'https://github.com/jonahwinninghoff/Causal-Analysis-Nursing-Home',
     aria: 'Portfolio Page - Effects of Nursing Home Facilities'
    },
    {question: 'Big Bird Transformer',
     path: 'https://github.com/jonahwinninghoff/Text-Summarization',
     aria: 'Portfolio Page - Big Bird Transformer'
    }
  ]

  return(
    <div className='introduction-container'>
      <div className='whatido-body' id='portfolio-height' onMouseMove={stripHoverEffect}>
        <div className='text-container'>
          <div className='JW-title' id='center-title' data-aos='fade-up'>Portfolio</div>
        </div>
        <ul className='grid-of-sample-works' ref={inputStripRef}>
          {items.map((value,i)=>{
            return(
              <li key = {i}>
                <Link
                  className='series-buttons'
                  data-aos='flip-left'
                  to={value.path} 
                  aria-label={value.aria}
                  key = {i} 
                  index = {i}
                >
                  <span className='LinkButton_text JW-patterns'>{value.question}</span>
                  <span className='series-drop-background' style={{transform: 'translate('+stripPositions[0]+'px,'+stripPositions[1]+'px)'}}>{value.question}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}