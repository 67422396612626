import React, { useState, useRef } from 'react';
import TextTransition, { presets } from 'react-text-transition';

import './Introduction.css';

const textArray = ['Data Scientist','AI/ML Engineer','Statistician','Full-Stack Developer','Frontend Developer','Backend Developer'];

export function Introduction(){
  // Hover Effect
  const inputBlurRef = useRef(null);
  const [positions, setPositions] = useState([0,0]);
  function blurHoverEffect(event){
    let mouseX = event.clientX;
    let mouseY = event.clientY;
    
    let centerX = window.innerWidth/2;
    let centerY = window.innerHeight/2;

    let pseudoX = window.innerWidth*mouseX/(centerX*2);
    let pseudoY = window.innerHeight*mouseY/(centerY*2);

    setPositions([pseudoX,pseudoY])
  };

  // Text Switch Effect
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return(
    <div className='introduction-container'>
      <div className='introduction-body' onMouseMove={blurHoverEffect}>
        <div className='blur-logo'>
          <svg viewBox="0 0 10 10" transform='scale(-1, 1)' style={{marginTop: positions[1], marginLeft: positions[0]-100}} width="200px" stroke="#4A55A9" fill='transparent' strokeWidth="0.35" strokeLinejoin='round' >
            <path ref={inputBlurRef} d = 'M 0.5 4 A 0.1 0.1 0 0 0 1.3 4 L 1.3 4 A 0.1 0.1 0 0 0 0.5 4 Z M 0.5 4 L 0.5 4 L 0.5 2.5 L 2.5 0.5 L 7 0.5 L 8 1.5 A 0.1 0.1 0 0 0 8 2.3 A 0.1 0.1 0 0 0 8 1.5 L 7 0.5 L 2.5 0.5 L 0.5 2.5 L 0.5 4 Z M 9 3.3 A 0.1 0.1 0 0 0 9 2.5 A 0.1 0.1 0 0 0 9 3.3 L 9 4.3 L 7.3 5.3 L 6.3 5.3 A 0.1 0.1 0 0 0 5.5 5.3 A 0.1 0.1 0 0 0 6.3 5.3 L 7.3 5.3 L 9 4.3 L 9 3.3 Z M 1.3 4 L 2 4 L 3.2 5.3 L 4 5.3 A 0.1 0.1 0 0 0 4.8 5.3 A 0.1 0.1 0 0 0 4 5.3 L 3.2 5.3 L 2 4 L 1.3 4 Z M 4.4 5.7 L 3.5 6.5 L 3.5 8 A 0.1 0.1 0 0 0 3.5 8.8 A 0.1 0.1 0 0 0 3.5 8 L 3.5 6.5 L 4.4 5.7 Z M 0.9 4.4 L 0.9 5.6 L 2 7.3 L 2 8.7 A 0.1 0.1 0 0 0 2 9.5 A 0.1 0.1 0 0 0 2 8.7 L 2 7.3 L 0.9 5.6 L 0.9 4.4 Z M 3.7 4.4 L 2.8 3.5 L 2.8 2.1 A 0.1 0.1 0 0 0 2.8 1.3 A 0.1 0.1 0 0 0 2.8 2.1 L 2.8 3.5 L 3.7 4.4 Z M 2.1 3.2 L 1.5 3.2 L 1.5 2.3 L 1.5 3.2 L 2.1 3.2 Z M 3.2 1.7 L 6.2 1.7 L 6.8 1.3 L 6.2 1.7 L 3.2 1.7 Z M 4 3.2 A 0.1 0.1 0 0 0 4 2.4 A 0.1 0.1 0 0 0 4 3.2 L 5 4.2 L 7.2 4.2 L 7.9 3.6 L 7.2 4.2 L 5 4.2 L 4 3.2 Z M 5 2.4 L 5.6 3 L 7 3 L 5.6 3 L 5 2.4 Z'/>
          </svg>
        </div>
        <h1 className = 'introduction-text' role = 'main'>
          Hi, my name is <span id = 'JW-introduction'>Jonah</span><b>.</b>
          <div id='introduction-subtitle'>
            <TextTransition springConfig={presets.gentle}>{textArray[index % textArray.length]}</TextTransition>
          </div>
        </h1>
      </div>
    </div>
  )
}